<template>
  <div>
    <div class="subHeader base noFlex">
      <div class="module-header">
        <h2>데모 관리</h2>
      </div>

      <div class="wtBox">
        <div class="module-filter">
          <div class="module-button">
            <select v-model="inputMode">
              <option value="" disabled>선택</option>
              <option value="username">이름</option>
              <option value="mobileNo">전화번호</option>
            </select>
          </div>
          <div class="box">
            <!-- <input type="text" placeholder="시작일" /> -->
            <div style="width: 80%">
              <Datepicker
                v-model="startYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="시작일"
              ></Datepicker>
            </div>
            <i class="material-icons">calendar_today</i>
          </div>
          <div class="box">
            <div style="width: 80%">
              <Datepicker
                v-model="endYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="종료일"
              ></Datepicker>
            </div>
            <i class="material-icons">calendar_today</i>
          </div>
          <!-- <select class="box">
            <option>조회기간</option>
            <option>이번주</option>
            <option>2주</option>
            <option>1개월</option>
            <option>3개월</option>
          </select>-->
          <div class="search">
            <fieldset>
              <legend>검색</legend>
              <input
                type="search"
                placeholder="검색어를 입력하세요"
                v-model="searchWord"
                @keyup.enter="getDemoList"
              />
              <button type="submit" class="material-icons" @click="getDemoList">
                search
              </button>
            </fieldset>
          </div>
          <!-- <div class="module-button">
            <button type="button" class="blue" @click="getDemoList">
              검색
            </button>
          </div> -->
          <button type="button" class="icoTxt" @click="reload">
            <i class="material-icons">replay</i>
            <b>새로고침</b>
          </button>
        </div>
      </div>

      <div class="module-config">
        <div class="wtBox config-list">
          <header class="flexL">
            <h3>데모 확인한 유저</h3>
            <span class="rubberBand"></span>
          </header>
          <table class="module-table lineRow">
            <tr>
              <th scope="col">번호</th>
              <th scope="col">요청일</th>
              <th scope="col">이름</th>
              <th scope="col">이메일</th>
              <th scope="col">전화번호</th>
              <th scope="col">소속</th>
            </tr>
            <tr v-for="(item, index) of demolist" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ moment(item.created_at).format("YYYY.MM.DD hh:mm") }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.mobileNo }}</td>
              <td>{{ item.company }}</td>
              <!-- 
              <td class="flex">
                <button
                  type="button"
                  class="btnView"
                  @click="handleMenu(item.id)"
                >
                  상세보기
                </button>
                <button
                  type="button"
                  style="margin-left: 5px"
                  class="btnView red"
                  @click="deleteMenu(item.id)"
                >
                  탈퇴
                </button>
              </td> -->
            </tr>
          </table>

          <div class="pagination">
            <!-- <div class="pagination" v-if="total > 0"> -->
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-show="total > 1" class="module-paging">
      <div class="inner">
        <ul class="skip prev">
          <li>
            <a href="#" @click="handlePaging(currentPage - 1)">
              <i class="material-icons">chevron_left</i>
            </a>
          </li>
        </ul>

        <ul class="paging">
          <li v-for="(page, i) in total" :key="i">
            <a
              href="#"
              :class="{ active: currentPage == i + 1 }"
              @click="handlePaging(page)"
              >{{ page }}</a
            >
          </li>
        </ul>

        <ul class="skip next">
          <li>
            <a href="#" @click="handlePaging(currentPage + 1)">
              <i class="material-icons">chevron_right</i>
            </a>
          </li>
        </ul>
      </div>
    </div> -->
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { fetchDemoList } from "@/api/member";
import moment from "moment";
import { mapState } from "vuex";
import { eventBus } from "@/main";
import { setNavbar } from "@/mixins/setNavbar";
import Datepicker from "vuejs-datepicker";
import { ko } from "vuejs-datepicker/dist/locale";
export default {
  components: { Datepicker },
  mixins: [setNavbar],
  data() {
    return {
      isEdit: false,
      ko: ko,
      moment: moment,
      display: true,
      confirmTitle: "",
      message1: "",
      message2: "",
      currentComponent: null,
      displayConfirm: false,
      demolist: [],
      dateKeyword: "",
      inputMode: "",
      searchWord: "",
      currentPage: 1,
      total: 0,
      typeCode: "",
      startYmd: "",
      endYmd: "",
      periodCode: "",
    };
  },
  mounted() {
    // this.email = this.userInfo.email;
    eventBus.$on("closeConfirm", () => {
      this.closeConfirm();
    });
    this.getDemoList();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    viewComponent(view) {
      this.currentComponent = view;
      // this.displayConfirm = true;
    },
    closeConfirm() {
      this.displayConfirm = false;
    },
    closeModal() {
      eventBus.$emit("closeModal", true);
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleEditMode() {
      this.isEdit = !this.isEdit;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDemoList();
    },
    handleClick(components) {
      event.preventDefault();
      let routeData = this.$router.resolve({ name: components });
      window.open(routeData.href, "_blank");
    },
    handleMenu(userId) {
      this.$router.push({ name: "userDetail", query: { id: userId } });
    },

    reload() {
      this.currentPage = 1;
      this.startYmd = "";
      this.endYmd = "";
      this.searchWord = "";
      this.demolist = [];
      this.getDemoList();
    },
    getDemoList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        startYmd: this.startYmd,
        endYmd: this.endYmd,
      };
      fetchDemoList(params).then((res) => {
        if (res.data.status == 200) {
          this.demolist = res.data.data;
          this.currentPage = res.data.page;
          this.total = res.data.total;
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
};
</script>
